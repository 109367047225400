import React from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';

import { isValidCPF } from 'utils/cpf/isValid';
import { isValidCNPJ } from 'utils/cnpj/isValid';
import { isValidPersonName } from 'utils/personName/isValid';
import { isValidPhone } from 'utils/phone/isValid';

const NuDSFormBR = ({ extraValidations, ...props }) => {
  const extraValidationsCombined = {
    ...extraValidations,
    cpf: isValidCPF,
    cnpj: isValidCNPJ,
    personName: isValidPersonName,
    phone: isValidPhone,
  };
  return (
    <Form extraValidations={extraValidationsCombined} {...props} />
  );
};

NuDSFormBR.propTypes = Form.propTypes;

NuDSFormBR.Step = Form.Step;

export default NuDSFormBR;
